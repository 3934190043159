import React from 'react';

import { RouteComponentProps } from '@reach/router';

import { OneColumn } from '@components/Layouts/OneColumn';
import NotFound from '@components/Routing/NotFound';

import { FEATURE_POLIOPLUS_FUND, isEnabled } from '@utils/features';

import { Helmet } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';

const PolioPlusFundPage: React.FC<RouteComponentProps> = () => {
  const { t } = useTranslation();
  const isPolioplusEnabled = isEnabled(FEATURE_POLIOPLUS_FUND);
  if (!isPolioplusEnabled) {
    return <NotFound />;
  }

  return (
    <OneColumn className="mb-20">
      <div className="widgetWrapper">
        <div id="donate-widget">
          <Helmet
            titleTemplate={`${t(
              'metadata.title.polioplus-fund',
              'Polioplus Fund'
            )} | ${t('metadata.title.default', 'My Rotary')}`}
          />
        </div>
      </div>
    </OneColumn>
  );
};

export default PolioPlusFundPage;
